import React from 'react';
import sizes from 'react-sizes';
import styled from 'styled-components';
import { GetButton } from 'components';

export default sizes(({ width }) => ({ viewportWidth: width && width }))(
  ({ info, viewportWidth, buttonLinkPath, buttonText, buttonWidth, venuePartnerWebsite }) => {
    const { about } = info;
    return (
      <Text>
        {about && (
          <>
            <h3>About</h3>
            <div dangerouslySetInnerHTML={{ __html: about }} />
            <GetButton
              buttonLinkPath={buttonLinkPath}
              buttonText={buttonText}
              buttonWidth={buttonWidth}
              viewportWidth={viewportWidth}
              // shine
            />
            {venuePartnerWebsite && (
              <>
                <br />
                <GetButton
                  buttonLinkPath={venuePartnerWebsite}
                  buttonText="Visit Venue Website"
                  buttonWidth={buttonWidth}
                  viewportWidth={viewportWidth}
                  // shine
                />
              </>
            )}
          </>
        )}
      </Text>
    );
  }
);

/* TODO: Refactor with Activity Info Text */
const Text = styled.div`
  a {
    color: var(--dynamic-blue);
  }

  > h3 {
    font-family: adelle, serif;
    font-size: 1.313rem;
    letter-spacing: 0.01em;
    font-weight: 700;
    margin-bottom: 0.625em;
  }

  > h3,
  > div p {
    color: var(--dynamic-dark-grey);
  }

  > ul {
    list-style: none;
    margin: 0;
  }

  > div p {
    margin-bottom: 1em;
    line-height: 1.313em;
  }

  ul {
    margin-left: 1rem;
    margin-bottom: 1em;
  }

  > div,
  > ul {
    font-family: 'proxima-nova', sans-serif;
  }

  ul {
    li {
      padding-bottom: 1rem;
    }
  }

  ol {
    li {
      padding-bottom: 1rem;
    }
  }

  > ul,
  > div:last-of-type {
    margin-bottom: 3.4em;
  }

  figure,
  > span.gatsby-resp-image-wrapper,
  div.video-without-caption {
    margin: 2rem 0;
  }

  figcaption {
    font-size: 0.85rem;
    margin-top: 0.25em;
    color: #3b444b;
    text-align: center;
  }

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media screen and (min-width: 1100px) {
    > div p {
      width: 96%;
    }
  }
`;
