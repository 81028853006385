import React, { useState, useEffect } from 'react';
import sizes from 'react-sizes';
import styled, { css } from 'styled-components';
import { CaseStudiesFilters, Cards as CaseStudiesCards } from 'components';
import getFiltersInitialState from './getFiltersInitialState.js';
import { sortByGridOrder } from 'utils';
import qs from 'qs';
import { InView } from 'react-intersection-observer';
import BlobYellowRed from 'images/team_building/misc/blob_yellow_red.svg';
import BlobBluePurple from 'images/team_building/misc/blob_blue_purple.svg';
import LightPurpleBackgroundSVG from 'images/shared/misc/blobs/light_purple_background.svg';

const CaseStudiesFiltersAndCards = ({
  caseStudies,
  gridOrder,
  pathname,
  viewportWidth,
  parameters
}) => {
  const allCards = gridOrder ? sortByGridOrder(caseStudies, gridOrder) : activities.edges;
  const [showTheseCards, setShowTheseCards] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchInput = ({ target }) => {
    const { value } = target;
    setSearchQuery(value);
  };

  const filterCategories = caseStudies.edges.map(({ node }) => {
    const { themes } = node.childMarkdownRemark.frontmatter;
    return {
      themes
    };
  });

  const [animateLightPurpleBackgroundSVG, setAnimateLightPurpleBackgroundSVG] = useState(false);

  const [filterValues, setFilterValues] = useState(getFiltersInitialState(filterCategories));

  useEffect(() => {
    const _checkedFilterValues = Object.entries(filterValues).reduce((acc, [filterTitle, nestedObj]) => {
      acc[filterTitle] = Object.entries(nestedObj).slice(1).reduce((_acc, [prop, value]) => {
        if(value === true) {
          _acc[prop] = value;
        }
        return _acc;
      }, {});
      return acc;
    }, {});
    const queryString = qs.stringify(_checkedFilterValues);
    if(queryString) {
    window.history.replaceState(null, null, `?${queryString}`);
    }
  }, [filterValues]);

  const filterCards = (card) => {
    const { themes } = card.node.childMarkdownRemark.frontmatter;

    const getCurrentlySelectedFiltersInThisCategory = (filters) =>
      Object.entries(filters)
        .slice(1)
        .filter((filter) => filter[1])
        .map((filter) => filter[0]);

    const condition1 =
      filterValues.themes['All Themes'] ||
      themes.some((theme) =>
        getCurrentlySelectedFiltersInThisCategory(filterValues.themes).includes(theme)
      );

    if (condition1) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    const filteredCards = allCards.filter(filterCards);
    setShowTheseCards(filteredCards.length ? filteredCards : []);
  }, [filterValues]);

  useEffect(() => {
    if (Object.keys(parameters).length > 0) {
      Object.keys(parameters).forEach((parameter) => {
        if (filterValues[parameter][parameters[parameter]] !== undefined) {
          const allFilter = Object.keys(filterValues[parameter])[0];
          const selectedFilter = parameters[parameter];
          const otherFilters = Object.keys(filterValues[parameter])
            .slice(1)
            .reduce((acc, filter) => {
              acc[filter] = false;
              return acc;
            }, {});

          setFilterValues((currentValues) => ({
            ...currentValues,
            [parameter]: {
              [allFilter]: false,
              ...otherFilters,
              [selectedFilter]: true,
            }
          }));
        }
      });
    }
  }, [parameters]);


  const handleFilterClick = ({ e, filterCategory, i }) => {
    // This logic checks which (if any) filters are ticked in this filter category and
    // toggles that category's allFilter (e.g. 'All Format/Support Options', 'All Locations', etc.)
    // accordingly.
    const allFilter = Object.keys(filterValues[filterCategory])[0];
    if (i === 0) {
      // Get all filters in this category except the allFilter and set them to false.
      const otherFilters = Object.keys(filterValues[filterCategory])
        .slice(1)
        .reduce((acc, filter) => {
          acc[filter] = false;
          return acc;
        }, {});
      setFilterValues((currentValues) => ({
        ...currentValues,
        [filterCategory]: {
          [allFilter]: e.target.checked,
          ...otherFilters
        }
      }));
    } else if (i !== 0) {
      setFilterValues((currentValues) => {
        // Set the allFilter to false if any of the other filters in this category are selected.
        const newFilterCategoryState = {
          ...currentValues[filterCategory],
          [allFilter]: false,
          [e.target.name]: e.target.checked
        };

        // If no filters are selected in this category, then select that category's allFilter.
        const allFiltersDeselected = Object.values(newFilterCategoryState)
          .slice(1)
          .every((boolean) => !boolean);

        if (allFiltersDeselected) {
          return {
            ...currentValues,
            [filterCategory]: {
              ...currentValues[filterCategory],
              [allFilter]: true,
              [e.target.name]: e.target.checked
            }
          };
        }

        return {
          ...currentValues,
          [filterCategory]: newFilterCategoryState
        };
      });
    }
  };

  useEffect(() => {
    const filteredCards = allCards.filter(filterCards);

    if (searchQuery === '') {
      setShowTheseCards(filteredCards.length ? filteredCards : []);
    } else if (searchQuery !== '') {
      const searchFilteredCards = filteredCards.filter(
        (card) =>
          card.node.childMarkdownRemark.frontmatter.themes.some((theme) =>
            theme.toLowerCase().includes(searchQuery.toLowerCase())
          ) ||
          card.node.childMarkdownRemark.frontmatter.title
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
      );
      setShowTheseCards(searchFilteredCards);
    }
  }, [filterValues, searchQuery]);
  

  return (
    <Wrapper>
      <LightPurpleBackground
        animateLightPurpleBackgroundSVG={animateLightPurpleBackgroundSVG}
        src={LightPurpleBackgroundSVG}
        alt="Purple blob"
        animationDuration="0.75s"
      />
      {viewportWidth >= 400 && (
        <StyledBlobYellowRed src={BlobYellowRed} alt="yellow and red blob" />
      )}
      {viewportWidth >= 400 && (
        <StyledBlobBluePurple src={BlobBluePurple} alt="blue and purple blob" />
      )}
      <InView onChange={setAnimateLightPurpleBackgroundSVG} triggerOnce>
        <FlexWrapper>
          {caseStudies && (
            <>
              <CaseStudiesFilters
                filterCategories={filterCategories}
                filterValues={filterValues}
                handleFilterClick={handleFilterClick}
                setFilterValues={setFilterValues}
                handleSearchInput={handleSearchInput}
                searchQuery={searchQuery}
              />
              <CaseStudiesCards showTheseCards={showTheseCards} pathname={pathname} />
            </>
          )}
        </FlexWrapper>
      </InView>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  position: relative;
  padding: 1.85rem 1rem 0;

  @media screen and (min-width: 75rem) {
    padding: 6.063rem 1rem 0;
  }

  @media screen and (min-width: 90rem) {
    padding: 6.063rem 0rem 0;
  }
`;

const FlexWrapper = styled.section`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  max-width: 1380px;
  margin: 0 auto;

  @media screen and (min-width: 54.375rem) {
    flex-direction: row;
    justify-content: center;
  }
`;

const StyledBlobYellowRed = styled.img`
  position: absolute;
  top: 240px;
  left: 0;
`;

const StyledBlobBluePurple = styled.img`
  position: absolute;
  top: 960px;
  right: 0;
`;

const LightPurpleBackground = styled.img`
  margin-left: 50%;
  position: absolute;
  top: 30px;
  transform: translate3d(100%, 0, 0);
  width: 2560px;

  ${({ animateLightPurpleBackgroundSVG, animationDuration }) =>
    animateLightPurpleBackgroundSVG &&
    css`
      transform: translate3d(-50%, 0, 0);
      transition: transform ${animationDuration} ease-in-out;
    `};
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(
  CaseStudiesFiltersAndCards
);
