import React from 'react';
import { Script } from 'gatsby';
import { useSiteMetadata } from '../../../hooks';
import defaultThumbnail from 'images/shared/misc/video-poster.jpeg';

const SEO = ({
  pageSpecificTitle,
  pageSpecificDescription,
  pageSpecificThumbnail,
  pageSpecificThumbnailAlt,
  pathname,
  children
}) => {
  const {
    defaultSiteTitle,
    defaultDescription,
    defaultThumbnailAlt,
    siteUrl,
    twitterPage,
    author
  } = useSiteMetadata();
  const siteTitle = pageSpecificTitle || defaultSiteTitle;
  const description = pageSpecificDescription || defaultDescription;
  const thumbnail = pageSpecificThumbnail || defaultThumbnail;
  const thumbnailAlt = pageSpecificThumbnailAlt || defaultThumbnailAlt;
  const url = `${siteUrl}${pathname}`;
  const image = `${siteUrl}${thumbnail}`;
  return (
    <>
      <title>{siteTitle}</title>
      <link rel="canonical" href={url} />
      <meta property="og:locale" content="en_GB" />
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:title" content={siteTitle} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />
      <meta property="og:image:url" content={image} />
      <meta property="og:image:secure_url" content={image} />
      <meta property="og:image:alt" content={thumbnailAlt} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:image" content={image} />
      <meta property="twitter:image:alt" content={thumbnailAlt} />
      <meta property="twitter:site" content={twitterPage} />
      <meta property="twitter:creator" content={twitterPage} />
      <meta property="twitter:title" content={siteTitle} />
      <meta property="twitter:description" content={description} />
      <meta property="author" content={author} />
      <Script src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" />
      {children}
    </>
  );
};

export default SEO;
