import React from 'react';
import sizes from 'react-sizes';
import styled from 'styled-components';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { GetButton } from 'components';
import QuotationMarksSVG from 'images/shared/misc/quotation_marks.svg';

export default sizes(({ width }) => ({ viewportWidth: width && width }))(
  ({ info, viewportWidth }) => {
    const { html, testimonial } = info;
    return (
      <>
        <Text>
          <div dangerouslySetInnerHTML={{ __html: html }} />
          <GetButton
            buttonLinkPath="#contact-us"
            buttonText="Contact Us"
            viewportWidth={viewportWidth}
          />
        </Text>
        {testimonial && (
          <TestimonialWrapper>
            <TestimonialHeader>
              <NameAndCompanyWrapper>
                <Name>{testimonial.personsName}</Name>
                <Company>{testimonial.companyName}</Company>
              </NameAndCompanyWrapper>
              {testimonial.companyLogo && (
                <Logo image={getImage(testimonial.companyLogo)} />
              )}
            </TestimonialHeader>
            <TestimonialBody>
              <QuotationMarks src={QuotationMarksSVG} alt="" />
              <Testimonial dangerouslySetInnerHTML={{ __html: testimonial.quote }} />
            </TestimonialBody>
          </TestimonialWrapper>
        )}
      </>
    );
  }
);

/* TODO: DRY refactor this with ClientTestimonialSlides. Note the extra position and bottom
properties here, and the changed max-width and max-height properties too. */
const TestimonialWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 360px;
  margin: 2.75em auto 0;
  /* max-height: 284px; */
  max-width: 540px;
  height: 100%;
  width: 100%;
`;

const TestimonialHeader = styled.div`
  background-color: var(--dynamic-dark-grey);
  padding: 0.75em 0.75em 0.75em 1.125em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px 10px 0px 0px;
  min-height: 84px;
`;

const NameAndCompanyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.p`
  color: #fff;
  font-weight: bold;
`;
const Company = styled.p`
  color: #fff;
  font-style: italic;
`;
const Logo = styled(GatsbyImage)`
  width: 60px;
  height: 60px;
`;

const TestimonialBody = styled.div`
  background-color: var(--site-background);
  padding: 1.75em 1.188em;
  height: 100%;
  font-style: italic;
  border-radius: 0px 0px 10px 10px;
`;
const QuotationMarks = styled.img`
  margin-bottom: 15px;
`;
const Testimonial = styled.p`
  color: var(--dynamic-dark-grey);
  margin-left: 1.36em;
  line-height: 1.25em;
  font-family: 'proxima-nova', sans-serif;
`;

/* TODO: Refactor with Activity Info Text */
const Text = styled.div`
  > div h1,
  > div h2 {
    font-family: adelle, serif;
    font-size: 1.313rem;
    letter-spacing: 0.01em;
    font-weight: 700;
    margin-bottom: 0.625em;
  }

  > div p {
    color: var(--dynamic-dark-grey);
  }

  > ul {
    list-style: none;
    margin: 0;
  }

  ul {
    li {
      padding-bottom: 1rem;
    }
  }

  ol {
    li {
      padding-bottom: 1rem;
    }
  }

  > div p {
    margin-bottom: 1em;
    line-height: 1.313em;
  }

  ul {
    margin-left: 1rem;
    margin-bottom: 1em;
  }

  > div,
  > ul {
    margin-bottom: 1.7em;
    font-family: 'proxima-nova', sans-serif;
  }

  iframe {
    width: 100%;
  }

  @media screen and (min-width: 1100px) {
    > div p {
      width: 96%;
    }
  }

  figure,
  > span.gatsby-resp-image-wrapper,
  div.video-without-caption {
    margin: 2rem 0;
  }

  figcaption {
    font-size: 0.85rem;
    margin-top: 0.25em;
    color: #3b444b;
    text-align: center;
  }

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
