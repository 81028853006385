import React from 'react';
import sizes from 'react-sizes';
import styled from 'styled-components';
import { GetButton } from 'components';

export default sizes(({ width }) => ({ viewportWidth: width && width }))(
  ({ brochureUrl, info, viewportWidth }) => {
    const { html } = info;
    return (
      <Text>
        {html && (
          <>
            <h3>More Info</h3>
            <div dangerouslySetInnerHTML={{ __html: html }} />
            {brochureUrl && (
              <span style={{ margin: '0 1rem 0 0' }}>
                <GetButton
                  buttonLinkPath={brochureUrl}
                  buttonText="Download Brochure"
                  buttonWidth="202px"
                  buttonHeight="44px"
                />
              </span>
            )}
            <GetButton
              buttonLinkPath="#contact-us"
              ariaLabel="get quote now"
              buttonText="Get Quote / Book Free Demo"
              buttonWidth="262px"
              buttonHeight="44px"
              viewportWidth={viewportWidth}
            />
          </>
        )}
      </Text>
    );
  }
);

/* TODO: Refactor with Activity Info Text */
const Text = styled.div`
  > h3 {
    font-family: adelle, serif;
    font-size: 1.313rem;
    letter-spacing: 0.01em;
    font-weight: 700;
    margin-bottom: 0.625em;
  }

  > h3,
  > div p {
    color: var(--dynamic-dark-grey);

    a {
      color: var(--dynamic-dark-grey);
      &:visted {
        color: var(--dynamic-dark-blue);
      }
    }
  }

  a {
    color: var(--dynamic-dark-grey);
    &:hover {
      color: var(--dynamic-dark-blue);
    }
  }

  > ul {
    list-style: none;
    margin: 0;
  }

  ul {
    li {
      padding-bottom: 1rem;
    }
  }

  ol {
    li {
      padding-bottom: 1rem;
    }
  }

  > div p {
    margin-bottom: 1em;
    line-height: 1.313em;
  }

  ul {
    margin-left: 1rem;
    margin-bottom: 1em;
  }

  > div,
  > ul {
    margin-bottom: 3.4em;
    font-family: 'proxima-nova', sans-serif;
  }

  figure,
  > span.gatsby-resp-image-wrapper,
  div.video-without-caption {
    margin: 2rem 0;
  }

  figcaption {
    font-size: 0.85rem;
    margin-top: 0.25em;
    color: #3b444b;
    text-align: center;
  }

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media screen and (min-width: 1100px) {
    > div p {
      width: 96%;
    }
  }
`;
