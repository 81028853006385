import React, { PureComponent } from 'react';
import sizes from 'react-sizes';
import { StaticQuery, graphql } from 'gatsby';
import { Cards } from 'components';
import styled, { css } from 'styled-components';
import { sortByGridOrder } from 'utils';
import { InView } from 'react-intersection-observer';
import BlobPurpleBlueSVG from 'images/event_management/blob_purple_blue.svg';
import BlobRedBlueSVG from 'images/event_management/blob_red_blue.svg';
import LightBlueBackgroundSVG from 'images/shared/misc/light_blue_background_3.svg';

class Services extends PureComponent {
  state = {
    animateLightBlueBackgroundSVG: false
  };

  handleSVG = (animateLightBlueBackgroundSVG) => this.setState({ animateLightBlueBackgroundSVG });

  render() {
    const { viewportWidth, pathname } = this.props;
    const { animateLightBlueBackgroundSVG } = this.state;
    return (
      <StaticQuery
        query={graphql`{
  eventManagementServices: allFile(
    filter: {sourceInstanceName: {eq: "cms_data_event_management_services"}, ext: {eq: ".md"}}
    sort: {childMarkdownRemark: {frontmatter: {title: ASC}}}
  ) {
    edges {
      node {
        id
        childMarkdownRemark {
          frontmatter {
            title
            summary
            featuredImg {
              alt
              src {
                childImageSharp {
                  gatsbyImageData(
                    width: 350
                    height: 200
                    transformOptions: {cropFocus: CENTER}
                    quality: 35
                    pngOptions: {compressionSpeed: 10}
                    placeholder: TRACED_SVG
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
        }
      }
    }
  }
  gridOrder: file(sourceInstanceName: {eq: "cms_data_settings_grid_orders"}) {
    childMarkdownRemark {
      frontmatter {
        eventManagementServices {
          title
        }
      }
    }
  }
}`}
        render={({ eventManagementServices, gridOrder }) => {
          const showTheseCards = gridOrder
            ? sortByGridOrder(eventManagementServices, gridOrder)
            : eventManagementServices.edges;
          return (
            <Wrapper>
              {eventManagementServices && (
                <Cards showTheseCards={showTheseCards} pathname={pathname} />
              )}
              <LightBlueBackground
                animateLightBlueBackgroundSVG={animateLightBlueBackgroundSVG}
                src={LightBlueBackgroundSVG}
                alt="light blue background"
                animationDuration="0.75s"
              />
              <InView onChange={this.handleSVG} triggerOnce>
                <DetailedOverview>
                  {viewportWidth > 900 && (
                    <>
                      <BlobPurpleBlue src={BlobPurpleBlueSVG} alt="purple and blue blob" />
                      <BlobRedBlue src={BlobRedBlueSVG} alt="red and blue blob" />
                    </>
                  )}
                  <p>
                    Our event management services cover a wide spectrum of events.
                    <br />
                    <br />
                    We provide an individual, dynamic service to all our clients, whatever size, to
                    help transform ideas into not just a successful event, but an event that has
                    been managed to the highest standards of professionalism to exceed your
                    expectations.
                    <br />
                    <br />
                    Our processes are made up of project management and event management services
                    with one leading into the other.
                    <br />
                    <br />
                    Our project management can take care of all pre event matters like pre-event
                    registrations, budget management, AV planning, event theming and design with 3D
                    visuals, event sourcing, logistics and event planning.
                    <br />
                    <br />
                    Our event management on the day takes care of transfers, event registration,
                    supplier management, AV production and over all event coordination from start to
                    finish, set-up to take down.
                  </p>
                </DetailedOverview>
              </InView>
            </Wrapper>
          );
        }}
      />
    );
  }
}

const Wrapper = styled.section`
  margin-bottom: 6em;
  padding: 11.1% 6.181% 0;
  position: relative;

  @media screen and (min-width: 1440px) {
    padding: 160px 0 0;
  }
`;

const DetailedOverview = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 7px 7px 16px rgba(189, 189, 189, 0.4);
  margin: 4.5em auto;
  max-width: 775px;
  padding: 12% 10%;
  position: relative;

  @media screen and (min-width: 500px) {
    padding: 3rem;
  }

  p {
    color: var(--dynamic-dark-grey);
    font-family: 'proxima-nova', sans-serif;
    font-size: calc(0.75rem + 0.835vw);
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.01em;
    /* margin-bottom: 0.9em; */

    @media screen and (min-width: 1200px) {
      font-size: 1.4rem;
    }
  }
`;

const BlobRedBlue = styled.img`
  position: absolute;
  right: 100%;
  bottom: -6px;
`;

const BlobPurpleBlue = styled.img`
  left: 100%;
  position: absolute;
  top: -53.5px;
`;

const LightBlueBackground = styled.img`
  margin-left: 50%;
  position: absolute;
  top: 200px;
  transform: translate3d(100%, 0, 0);
  width: 2560px;

  ${({ animateLightBlueBackgroundSVG, animationDuration }) =>
    animateLightBlueBackgroundSVG &&
    css`
      transform: translate3d(-50%, 0, 0);
      transition: transform ${animationDuration} ease-in-out;
    `};
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(Services);
